@import url(../../node_modules/normalize.css/normalize.css);
/* line 4, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_box-sizing.scss */
html {
  box-sizing: border-box; }

/* line 9, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_box-sizing.scss */
*, *::after, *::before {
  box-sizing: inherit; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_buttons.scss */
button, [type='button'], [type='reset'], [type='submit'] {
  appearance: none;
  background-color: #949494;
  border: 0;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "museo-sans", sans-serif;
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  font-weight: 600;
  line-height: 1;
  padding: 0.75em 1.5em;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  /* line 22, /Users/butenschoen/Sites/beutin.de/src/scss/base/_buttons.scss */
  button:hover, button:focus, [type='button']:hover, [type='button']:focus, [type='reset']:hover, [type='reset']:focus, [type='submit']:hover, [type='submit']:focus {
    background-color: #767676;
    color: #fff; }
  /* line 28, /Users/butenschoen/Sites/beutin.de/src/scss/base/_buttons.scss */
  button:disabled, [type='button']:disabled, [type='reset']:disabled, [type='submit']:disabled {
    cursor: not-allowed;
    opacity: 0.5; }
    /* line 32, /Users/butenschoen/Sites/beutin.de/src/scss/base/_buttons.scss */
    button:disabled:hover, [type='button']:disabled:hover, [type='reset']:disabled:hover, [type='submit']:disabled:hover {
      background-color: #949494; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
fieldset {
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0; }

/* line 8, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
legend {
  font-weight: 600;
  margin-bottom: 0.375em;
  padding: 0; }

/* line 14, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.375em; }

/* line 20, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
input,
select,
textarea {
  display: block;
  font-family: "museo-sans", sans-serif;
  font-size: 1em; }

/* line 28, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
[type='color'], [type='date'], [type='datetime'], [type='datetime-local'], [type='email'], [type='month'], [type='number'], [type='password'], [type='search'], [type='tel'], [type='text'], [type='time'], [type='url'], [type='week'], input:not([type]), textarea {
  appearance: none;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-bottom: 0.75em;
  padding: 0.5em;
  transition: border-color 150ms ease;
  width: 100%; }
  /* line 40, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
  [type='color']:hover, [type='date']:hover, [type='datetime']:hover, [type='datetime-local']:hover, [type='email']:hover, [type='month']:hover, [type='number']:hover, [type='password']:hover, [type='search']:hover, [type='tel']:hover, [type='text']:hover, [type='time']:hover, [type='url']:hover, [type='week']:hover, input:not([type]):hover, textarea:hover {
    border-color: #b1b1b1; }
  /* line 44, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
  [type='color']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='email']:focus, [type='month']:focus, [type='number']:focus, [type='password']:focus, [type='search']:focus, [type='tel']:focus, [type='text']:focus, [type='time']:focus, [type='url']:focus, [type='week']:focus, input:not([type]):focus, textarea:focus {
    border-color: #949494;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px rgba(135, 135, 135, 0.7);
    outline: none; }
  /* line 50, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
  [type='color']:disabled, [type='date']:disabled, [type='datetime']:disabled, [type='datetime-local']:disabled, [type='email']:disabled, [type='month']:disabled, [type='number']:disabled, [type='password']:disabled, [type='search']:disabled, [type='tel']:disabled, [type='text']:disabled, [type='time']:disabled, [type='url']:disabled, [type='week']:disabled, input:not([type]):disabled, textarea:disabled {
    background-color: #f2f2f2;
    cursor: not-allowed; }
    /* line 54, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
    [type='color']:disabled:hover, [type='date']:disabled:hover, [type='datetime']:disabled:hover, [type='datetime-local']:disabled:hover, [type='email']:disabled:hover, [type='month']:disabled:hover, [type='number']:disabled:hover, [type='password']:disabled:hover, [type='search']:disabled:hover, [type='tel']:disabled:hover, [type='text']:disabled:hover, [type='time']:disabled:hover, [type='url']:disabled:hover, [type='week']:disabled:hover, input:not([type]):disabled:hover, textarea:disabled:hover {
      border: 1px solid #ddd; }
  /* line 59, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
  [type='color']::placeholder, [type='date']::placeholder, [type='datetime']::placeholder, [type='datetime-local']::placeholder, [type='email']::placeholder, [type='month']::placeholder, [type='number']::placeholder, [type='password']::placeholder, [type='search']::placeholder, [type='tel']::placeholder, [type='text']::placeholder, [type='time']::placeholder, [type='url']::placeholder, [type='week']::placeholder, input:not([type])::placeholder, textarea::placeholder {
    color: #989898; }

/* line 64, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
textarea {
  resize: vertical; }

/* line 68, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
[type="checkbox"],
[type="radio"] {
  display: inline;
  margin-right: 0.375em; }

/* line 74, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
[type="file"] {
  margin-bottom: 0.75em;
  width: 100%; }

/* line 79, /Users/butenschoen/Sites/beutin.de/src/scss/base/_forms.scss */
select {
  margin-bottom: 0.75em;
  width: 100%; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_layout.scss */
html {
  box-sizing: border-box; }

/* line 5, /Users/butenschoen/Sites/beutin.de/src/scss/base/_layout.scss */
*,
*::before,
*::after {
  box-sizing: inherit; }

/* line 11, /Users/butenschoen/Sites/beutin.de/src/scss/base/_layout.scss */
html,
body {
  height: 100%; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
ul,
ol {
  list-style-type: none;
  margin: 0;
  padding: 0; }

/* line 8, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
dl {
  margin: 0; }

/* line 12, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
dt {
  font-weight: 600;
  margin: 0; }

/* line 17, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
dd {
  margin: 0; }

/* line 22, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
.datenschutz-section ol {
  list-style: decimal; }

/* line 26, /Users/butenschoen/Sites/beutin.de/src/scss/base/_lists.scss */
.datenschutz-section ul {
  list-style: disc; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_media.scss */
figure {
  margin: 0; }

/* line 5, /Users/butenschoen/Sites/beutin.de/src/scss/base/_media.scss */
img,
picture {
  margin: 0;
  max-width: 100%; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_tables.scss */
table {
  border-collapse: collapse;
  margin: 0.75em 0;
  table-layout: fixed;
  width: 100%; }

/* line 8, /Users/butenschoen/Sites/beutin.de/src/scss/base/_tables.scss */
th {
  border-bottom: 1px solid #a6a6a6;
  font-weight: 600;
  padding: 0.75em 0;
  text-align: left; }

/* line 15, /Users/butenschoen/Sites/beutin.de/src/scss/base/_tables.scss */
td {
  border-bottom: 1px solid #ddd;
  padding: 0.75em 0; }

/* line 20, /Users/butenschoen/Sites/beutin.de/src/scss/base/_tables.scss */
tr,
td,
th {
  vertical-align: middle; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
body {
  color: #545454;
  font-family: "museo-sans", sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  text-rendering: optimizeLegibility; }

/* line 10, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "adonis-web", Georgia, serif;
  font-size: 1.25em;
  font-weight: 400;
  letter-spacing: .125em;
  line-height: 1.2;
  margin: 0 0 0.75em;
  text-transform: uppercase; }

/* line 25, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
p {
  margin: 0 0 0.75em; }

/* line 29, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
a {
  color: #949494;
  text-decoration: none;
  transition: color 150ms ease; }
  /* line 34, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
  a:active, a:focus, a:hover {
    color: #6f6f6f; }

/* line 41, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
hr {
  border-bottom: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  margin: 1.5em 0; }

/* line 49, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
* + h2 {
  margin-top: 1.5625em; }

/* line 53, /Users/butenschoen/Sites/beutin.de/src/scss/base/_typography.scss */
* + h3 {
  margin-top: 1.5625em; }

/* line 3, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
.nav ul a {
  text-transform: uppercase; }

/* line 8, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
.nav--top {
  background: #fff; }
  /* line 11, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .nav--top ul {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 64em;
    padding: 1em 2em 0.5em;
    width: 100%; }
    /* line 21, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .nav--top ul li {
      margin-left: 1em; }
    /* line 25, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .nav--top ul a {
      font-size: 0.8em; }
      /* line 28, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
      .nav--top ul a:hover {
        color: #41557D; }

/* line 35, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
.nav--main {
  border-radius: 0 0 1em 1em;
  border-top: 2px solid #fff;
  margin: 0 auto;
  max-width: 40em;
  text-align: center;
  width: 100%; }
  /* line 43, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .nav--main ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    padding: 0 3em; }
    /* line 49, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .nav--main ul a {
      color: #545454;
      display: block;
      padding: 0.5em 0;
      position: relative; }
      /* line 55, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
      .nav--main ul a::after {
        border-bottom: 2px solid #41557D;
        content: '';
        left: -1.5em;
        position: absolute;
        right: -1.5em;
        top: calc(100% - 1px);
        transform: scale(0, 1);
        transition: transform 150ms ease; }
      /* line 66, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
      .nav--main ul a:hover {
        color: #41557D; }
        /* line 69, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
        .nav--main ul a:hover::after {
          transform: scale(1, 1); }
    /* line 76, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .nav--main ul .active a {
      color: #41557D; }
      /* line 79, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
      .nav--main ul .active a::after {
        transform: scale(1, 1); }

/* line 88, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
.nav-wrap {
  background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.5));
  border-bottom: 1px solid transparent;
  transition: background-color 0.25s; }
  /* line 97, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .nav-wrap h1 {
    transition: padding 0.25s; }
    /* line 100, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .nav-wrap h1 a {
      color: #545454;
      transition: color 150ms ease; }
      /* line 104, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
      .nav-wrap h1 a:hover {
        color: #41557D; }
  /* line 110, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .scrolled .nav-wrap {
    background-color: #fff;
    border-bottom-color: #f2f2f2;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10; }
    /* line 119, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
    .scrolled .nav-wrap h1 {
      padding: 0.5em 0 0.125em; }

/* line 125, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
.scroll-top {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 0 2px rgba(84, 84, 84, 0.5);
  bottom: 5vh;
  cursor: pointer;
  height: calc(32px + 0.25em);
  left: 50vw;
  margin-left: -16px;
  opacity: 0.75;
  padding: 0.125em;
  pointer-events: none;
  position: fixed;
  transform: scale(0);
  transition: transform 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275); }
  /* line 141, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .scroll-top img {
    height: 32px;
    width: 32px; }
  /* line 146, /Users/butenschoen/Sites/beutin.de/src/scss/components/_navigation.scss */
  .scrolled .scroll-top {
    pointer-events: all;
    transform: scale(1); }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
.nav-section {
  max-width: 100vw;
  min-height: 80vh;
  overflow: hidden;
  position: relative; }
  /* line 6, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .nav-section .section-background {
    bottom: 0;
    left: 0;
    right: 0; }
    @media (max-width: 900px) {
      /* line 6, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .nav-section .section-background {
        left: -33%;
        right: -33%; } }
    @media (max-width: 600px) {
      /* line 6, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .nav-section .section-background {
        left: -67%;
        right: -67%; } }
  /* line 19, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .nav-section h1 {
    font-size: 2.44141em;
    margin-bottom: 0;
    padding: 1em 0;
    text-align: center;
    width: 100%; }

/* line 28, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
.section-background {
  position: absolute;
  overflow: visible;
  z-index: -1; }
  /* line 32, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .section-background img {
    height: auto;
    width: 100%; }
    @media (max-width: 1100px) {
      /* line 32, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .section-background img {
        font-family: 'object-fit: cover;';
        height: 100%;
        object-fit: cover;
        width: 100%; } }

/* line 44, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
.text-section {
  background: #fff;
  padding: 4em 0;
  text-align: center; }
  /* line 48, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .text-section .section-wrap {
    margin: 0 auto;
    max-width: 40em;
    width: 100%; }
  /* line 53, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .text-section h2 {
    font-size: 1.95312em;
    margin-bottom: 1.5em;
    position: relative; }
    /* line 57, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
    .text-section h2::after {
      border-top: 2px solid #949494;
      content: '';
      left: 50%;
      margin-left: -2.5rem;
      position: absolute;
      top: 150%;
      width: 5rem; }
    @media (max-width: 22.5em) {
      /* line 53, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .text-section h2 {
        font-size: 1.5625em; } }
  /* line 70, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .text-section--inverse {
    background: #545454; }
    /* line 72, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
    .text-section--inverse h2,
    .text-section--inverse p {
      color: #fff; }
  @media (max-width: 64em) {
    /* line 44, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
    .text-section {
      padding-left: .75em;
      padding-right: .75em; } }

/* line 83, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
.bg-history {
  max-width: 100vw;
  min-height: 50vh;
  overflow: hidden;
  position: relative; }
  /* line 88, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
  .bg-history .section-background {
    left: 0;
    right: 0;
    top: -50%; }
    @media (max-width: 900px) {
      /* line 88, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .bg-history .section-background {
        left: -33%;
        right: -33%; } }
    @media (max-width: 600px) {
      /* line 88, /Users/butenschoen/Sites/beutin.de/src/scss/components/_sections.scss */
      .bg-history .section-background {
        left: -67%;
        right: -67%; } }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
.columns-wrap {
  max-width: 64em;
  margin-left: auto;
  margin-right: auto; }
  /* line 20, /Users/butenschoen/Sites/beutin.de/src/scss/neat/mixins/_clearfix.scss */
  .columns-wrap::after {
    clear: both;
    content: "";
    display: block; }
  /* line 3, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
  .columns-wrap h2 {
    float: left;
    display: block;
    margin-right: 2.35765%;
    width: 100%; }
    /* line 89, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_span-columns.scss */
    .columns-wrap h2:last-child {
      margin-right: 0; }
    /* line 20, /Users/butenschoen/Sites/beutin.de/src/scss/neat/mixins/_clearfix.scss */
    .columns-wrap h2::after {
      clear: both;
      content: "";
      display: block; }
  /* line 7, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
  .columns-wrap .col {
    padding-top: 1em; }
    @media (min-width: 600px) and (max-width: 899px) {
      /* line 7, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
      .columns-wrap .col {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 48.82117%; }
        /* line 89, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_span-columns.scss */
        .columns-wrap .col:last-child {
          margin-right: 0; }
        /* line 78, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_omega.scss */
        .columns-wrap .col:nth-child(3n) {
          margin-right: 0; }
        /* line 83, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_omega.scss */
        .columns-wrap .col:nth-child(3n+1) {
          clear: left; } }
    @media (min-width: 600px) {
      /* line 7, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
      .columns-wrap .col {
        float: left;
        display: block;
        margin-right: 2.35765%;
        width: 23.23176%; }
        /* line 89, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_span-columns.scss */
        .columns-wrap .col:last-child {
          margin-right: 0; }
        /* line 78, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_omega.scss */
        .columns-wrap .col:nth-child(5n) {
          margin-right: 0; }
        /* line 83, /Users/butenschoen/Sites/beutin.de/src/scss/neat/grid/_omega.scss */
        .columns-wrap .col:nth-child(5n+1) {
          clear: left; } }
    /* line 17, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
    .columns-wrap .col img {
      margin: 0 auto;
      height: 6.67rem;
      padding-bottom: 2em;
      width: auto; }
    /* line 23, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
    .columns-wrap .col .description {
      border-top: 1px solid rgba(255, 255, 255, 0.5);
      color: #949494;
      padding-top: 1.5em;
      position: relative; }
      /* line 28, /Users/butenschoen/Sites/beutin.de/src/scss/components/_columns.scss */
      .columns-wrap .col .description a,
      .columns-wrap .col .description .white {
        color: #fff; }

/* line 2, /Users/butenschoen/Sites/beutin.de/src/scss/components/_content.scss */
.impressum-section table {
  margin: 0 auto;
  max-width: 30em;
  text-align: left;
  width: 100%; }
  /* line 9, /Users/butenschoen/Sites/beutin.de/src/scss/components/_content.scss */
  .impressum-section table tr td:last-child {
    text-align: right; }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_footer.scss */
.page-footer {
  padding-top: 0.5em;
  text-align: center; }
  /* line 5, /Users/butenschoen/Sites/beutin.de/src/scss/components/_footer.scss */
  .page-footer .footer-wrap {
    margin: 0 auto;
    max-width: 64em;
    width: 100%; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
.history-timeline {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 8em;
  position: relative;
  width: 100%; }
  @media (max-width: 64em) {
    /* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline {
      padding: 6em 4em; } }
  @media (max-width: 48em) {
    /* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline {
      padding: 6em 2em; } }
  @media (max-width: 40em) {
    /* line 1, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline {
      padding: 6em 1em; } }
  /* line 21, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
  .history-timeline::before {
    border-top: 1px solid #ddd;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 50%; }
  /* line 30, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
  .history-timeline .timeline-point {
    border: 1px solid #545454;
    background: #fff;
    border-radius: 100%;
    cursor: pointer;
    padding: 1em;
    position: relative; }
    @media (max-width: 22.5em) {
      /* line 30, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
      .history-timeline .timeline-point {
        padding: 0.75em; } }
    /* line 42, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point:hover, .history-timeline .timeline-point.active {
      border-color: #41557D; }
      /* line 46, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
      .history-timeline .timeline-point:hover > h4, .history-timeline .timeline-point.active > h4 {
        color: #41557D; }
      /* line 50, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
      .history-timeline .timeline-point:hover::after, .history-timeline .timeline-point.active::after {
        border-color: #41557D; }
    /* line 55, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point > h4 {
      color: #949494;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      transition: color 150ms ease; }
      @media (max-width: 22.5em) {
        /* line 55, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
        .history-timeline .timeline-point > h4 {
          font-size: 1em; } }
    /* line 67, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point::before {
      background: #41557D;
      border-radius: 100%;
      bottom: 5px;
      content: '';
      left: 5px;
      position: absolute;
      right: 5px;
      top: 5px;
      transform: scale(0);
      transition: transform 0.125s; }
    /* line 80, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point::after {
      border-left: 1px solid #949494;
      content: '';
      margin-left: -1px;
      position: absolute;
      left: 50%;
      height: 200%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1923'] > h4 {
      top: -200%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1923']::after {
      bottom: 100%;
      height: 110%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1945'] > h4 {
      top: 200%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1945']::after {
      top: 100%;
      height: 90%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1960'] > h4 {
      top: -300%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1960']::after {
      bottom: 100%;
      height: 210%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1990'] > h4 {
      top: 250%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='1990']::after {
      top: 100%;
      height: 140%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2000'] > h4 {
      top: -200%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2000']::after {
      bottom: 100%;
      height: 110%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2010'] > h4 {
      top: -275%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2010']::after {
      bottom: 100%;
      height: 185%; }
    /* line 94, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2017'] > h4 {
      top: 300%; }
    /* line 98, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point[data-year='2017']::after {
      top: 100%;
      height: 190%; }
    /* line 113, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-timeline .timeline-point.active::before {
      transform: scale(1); }

/* line 120, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
.history-info {
  margin: 1em auto;
  max-width: 48em;
  width: 100%; }
  /* line 125, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
  .history-info > div {
    display: none; }
    /* line 128, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
    .history-info > div.active {
      animation: fadeIn 0.5s forwards;
      display: block; }
    @media (max-width: 48em) {
      /* line 125, /Users/butenschoen/Sites/beutin.de/src/scss/components/_history.scss */
      .history-info > div {
        padding: 0 1em; } }

/* line 14, /Users/butenschoen/Sites/beutin.de/src/scss/style.scss */
html,
body {
  height: 100%; }
